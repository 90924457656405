var exports = {};
exports = {
  ":root,\n[data-theme]": {
    "backgroundColor": "hsla(var(--b1) / var(--tw-bg-opacity, 1))",
    "color": "hsla(var(--bc) / var(--tw-text-opacity, 1))"
  },
  "html": {
    "WebkitTapHighlightColor": "transparent"
  }
};
export default exports;